.App {
  text-align: center;

  .App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .App-logo-wrapper {
      position:absolute;
      font-size: 20vmin;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .App-logo {
        pointer-events: none;
      }
    
    }

    .App-header-text {
      position:relative;
      top: 20vmin;

    }

  }

}

@keyframes App-bg-logo-rotate {
  from {rotate: 0deg;}
  to {rotate: 360deg;}
}

@keyframes App-bg-logo-pulse {
  from {opacity: 1;}
  to {opacity: 0.0;}
}